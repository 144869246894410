import axios from 'axios'
import nprogress from 'nprogress'
import 'nprogress/nprogress.css'
// 创建axios实例
const http = axios.create({
  baseURL: '/gw/customer-server',
  timeout: 5000
})

// 添加请求拦截器
http.interceptors.request.use(function (config) {
  // 在发送请求之前做些什么
  // console.log('config', config)
  nprogress.start()
  // config.headers.Authorization = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJneWYxIiwibmljayI6IumDreS6mumjnjEiLCJqdGkiOiJ0VENjcjlQaVFTMWZodUJXQWVoR0NOOUdydFFQTHFyNCIsImF1ZCI6IiIsImlhdCI6MTY0ODA5OTM5Njg1MCwiYWxvbmUiOmZhbHNlLCJleHAiOjE2NDkzOTUzOTZ9.ImGmH9JVYgP7hlk4d2BywL2-evID7-F_a0gBPi1ebNiAy4C3DcTmyGavTZVWmB9QLtPxtN7DLFnN4ZOh-GoYBA'
  return config
}, function (error) {
  // 对请求错误做些什么
  return Promise.reject(error)
})

// 添加响应拦截器
http.interceptors.response.use(function (response) {
  // console.log('response', response)
  // 对响应数据做点什么
  nprogress.done()
  return response.data
}, function (error) {
  // 对响应错误做点什么
  return Promise.reject(error)
})

export default http
