import { render, staticRenderFns } from "./Honor.vue?vue&type=template&id=70489fe6&scoped=true&"
import script from "./Honor.vue?vue&type=script&lang=js&"
export * from "./Honor.vue?vue&type=script&lang=js&"
import style0 from "./Honor.vue?vue&type=style&index=0&id=70489fe6&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "70489fe6",
  null
  
)

export default component.exports