<template>
  <div class="exw-local-manager">
    <div class="banner"></div>
    <div class="slogan">
      <div class="text">
        China’s Largest Container<br />
        Trucking Fleet<br />
        <span class="help-text" style="">
          We Can Help You Change FOB To EXW
        </span>
      </div>
    </div>
    <div class="container">
      <BusinessConsulting class="business-consulting"></BusinessConsulting>
      <!-- <Introduce></Introduce> -->
      <EXWLocalManagerIntroduce></EXWLocalManagerIntroduce>
      <div class="video">
      <video width="1200" height="590" loop  controls poster='' ref="video">
          <source src="@/assets/EXW_Local_Manager/video1.mp4" type="video/mp4">
          Your browser doesn't support HTML5 video.
      </video>
      <div class="cover" @click="play" v-if="playIconShow">
        <img src="@/assets/EXW_Local_Manager/shipin_fengmian.png" alt="">
        <img src="@/assets/EXW_Local_Manager/shipin2.png" alt="" class="icon">
      </div>
    </div>
    </div>
    <Numbers></Numbers>
    <div class="container">
      <Services></Services>
    </div>
  </div>
</template>

<script>
import BusinessConsulting from '@/components/BusinessConsulting'
// import Introduce from './components/Introduce'
import EXWLocalManagerIntroduce from './components/EXWLocalManagerIntroduce'
import Numbers from './components/Numbers'
import Services from './components/Services'
// import { startPagePoint, endPagePoint } from '@/api/pagePoint.js'
import util from '@/utils/request_two.js'
export default {
  name: 'EXWLocalManager',
  components: {
    BusinessConsulting,
    // Introduce,
    EXWLocalManagerIntroduce,
    Numbers,
    Services
  },
  data () {
    return {
      id: 0,
      playIconShow: true
    }
  },
  async created () {
    console.log(1111111)
    util.en_customer_ajax_post(
      this,
      '/customer-consultion/startPagePoint',
      {
        page: this.$route.path,
        source: 'pc'
      },
      (res) => {
        console.log('12e', res)
        this.id = res.data.data
      }
    )
    // const res = await startPagePoint({
    //   page: this.$route.path,
    //   source: 'pc'
    // })
    // console.log(res)
    // this.id = res.data
  },

  async beforeDestroy () {
    console.log('这是id', this.id)
    util.en_customer_ajax_post(
      this,
      '/customer-consultion/endPagePoint',
      {
        id: this.id
      },
      (res) => {
        console.log(res)
      }
    )
    // const res = await endPagePoint({
    //   id: this.id
    // })
    // console.log(res)
  },
  methods: {
    play () {
      this.$refs.video.play()
      this.playIconShow = false
    }
  }
}
</script>

<style lang="less" scoped>
@import url("../../style/mixin.less");
// @color: red;
.exw-local-manager {
  position: relative;
  line-height: 30px;
  font-weight: 400;
  .banner {
    .banner("~@/assets/EXW_Local_Manager/EXW_banner.png");
  }
  .slogan {
    .banner-slogan-wrap(176px);
    .text {
      .banner-slogan(44px, #333, 44px);
      background: -webkit-linear-gradient(#fff, #fff);
      -webkit-background-clip: text;
      -webkit-text-fill-color: #000;
      -webkit-text-stroke:4px transparent;
      .help-text {
        .banner-slogan(44px, #333, 44px);
        font-size:30px;
        display: inline-block;
        margin-top: 25px;
        background: -webkit-linear-gradient(#fff, #fff);
        -webkit-background-clip: text;
        -webkit-text-fill-color: #000;
        -webkit-text-stroke:4px transparent;
      }
    }
  }
  .container {
    position: relative;
    // padding-top: 200px;
    .business-consulting {
      position: absolute;
      top: -38px;
    }
  }
  .video {
    margin-top: 150px;
  margin-bottom: 118px;
  position: relative;
  .cover {
position: absolute;
top: 0;
left: 0;
.icon {
  cursor: pointer;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
  }
}
}
</style>
