<template>
  <div class="contact-us">
    <div class="banner"></div>
    <div class="slogan">
      <div class="text">We’d <br />Love To Hear From You!</div>
    </div>
    <div class="container">
      <div class="title">Contact Details</div>
      <div class="title-underline"></div>
      <div class="address">
        <div class="places">
          <div
            class="place"
            :class="{ active: index === activeIndex }"
            v-for="(item, index) in place"
            :key="index"
            @click="activeIndex = index"
          >
            {{ item }}
          </div>
        </div>
        <div class="info">
          <div>Tel: +86‐021‐36622199</div>
          <div>
            Email: customerservice@duckbillscm.com, marketing@duckbillscm.com
          </div>
          <div>Add:{{ selectedItem.address }}</div>
          <div>Zip code:{{ selectedItem.zipCode }}</div>
          <img
            src="@/assets/contact_us/weizhi.png"
            alt=""
            class="location"
            :style="selectedItem.locationStyle"
            @mouseenter="hoverImgShow = true"
            @mouseleave="hoverImgShow = false"
          />
          <div
            class="text"
            :style="selectedItem.textStyle"
            @mouseenter="hoverImgShow = true"
            @mouseleave="hoverImgShow = false"
          >
            DUCKBILL
          </div>
          <img :src="selectedItem.img" alt="" class="map" />
          <img
            :src="selectedItem.hoverImg"
            alt=""
            class="hover-img"
            :style="selectedItem.hoverImgStyle"
            v-show="hoverImgShow"
          />
        </div>
      </div>
      <BusinessConsulting></BusinessConsulting>
    </div>
  </div>
</template>

<script>
import { addressData } from './data'
import BusinessConsulting from '@/components/BusinessConsulting'
import { startPagePoint, endPagePoint } from '@/api/pagePoint.js'

export default {
  data () {
    return {
      addressData,
      activeIndex: 0,
      place: [
        'CNSHA',
        'CNNBO',
        'CNLYG',
        ' CNSHE',
        'CNQIN',
        'CNTJN',
        'CNTCG',
        'CNGUA'
      ],
      hoverImgShow: false,
      id: 0
    }
  },
  components: {
    BusinessConsulting
  },
  computed: {
    selectedItem () {
      return this.addressData.find((item) => item.id === this.activeIndex)
    }
  },
  async created () {
    const res = await startPagePoint({
      page: this.$route.path,
      source: 'pc'
    })
    // console.log(res)
    this.id = res.data
  },
  async beforeDestroy () {
    const res = await endPagePoint({
      id: this.id
    })
    console.log(res)
  }
}
</script>

<style lang="less" scoped>
@import url("../../style/mixin.less");
.contact-us {
  text-align: center;
  .banner {
    .banner("~@/assets/contact_us/banner3_wuzi.png");
  }
  .slogan {
    .banner-slogan-wrap(210px);
    .text {
      text-align: left;
      .banner-slogan(44px, #333);
    }
  }
  .container {
    padding: 80px 0 239px;
    .title-underline {
      .title-underline(100px);
      margin-left: 560px;
    }
    .address {
      margin-bottom: 13px;
      .places {
        display: flex;
        justify-content: space-between;
        .place {
          width: 132px;
          height: 30px;
          line-height: 30px;
          cursor: pointer;
          color: #afb9be;
          border: 1px solid #f0f3f5;
          &.active {
            background-color: #ff9100;
            color: #fff;
            border: none;
          }
        }
      }
      .info {
        margin-top: 30px;
        line-height: 30px;
        color: #333;
        text-align: left;
        position: relative;
        .location {
          width: 30px;
          height: 36px;
          position: absolute;
        }
        .text {
          position: absolute;
          font-size: 30px;
          color: #ff9100;
        }
        .map {
          width: 1200px;
          margin-top: 20px;
        }
        .hover-img {
          position: absolute;
        }
      }
    }
  }
}
</style>
