<template>
  <div class="introduce">
    <div class="title">Who We Are</div>
    <div class="title-underline"></div>
    <div class="content">
      <span style="font-weight: 600;font-size: 26px;color: #ff9100;">Duckbill</span>, founded in 2017, is China’s leading and fastest growing solution-based container trucking fleet. Headquartered in Shanghai, our network also covers all the other main harbor ports including Ningbo, Shenzhen, Guangzhou, Qingdao, Tianjin, and Taicang. By utilizing big data and AI, we dynamically manage 2,100+ trucks, 100+ professional inspectors and customer service personnel. Duckbill Tech is our state-of-the-art digital platform which offers our clients a fully online experience, you can do online inquiry & instant quotations, online bookings, inspection services plus real-time 24/7 track & trace under our 'EXW local manager' program.
    </div>
    <!-- <div class="video">
      <video width="1200" height="590" loop  controls poster='' ref="video">
    <source src="@/assets/EXW_Local_Manager/video1.mp4" type="video/mp4">
    Your browser doesn't support HTML5 video.
</video>
<div class="cover" @click="play" v-if="playIconShow">
  <img src="@/assets/EXW_Local_Manager/shipin_fengmian.png" alt="">
  <img src="@/assets/EXW_Local_Manager/shipin2.png" alt="" class="icon">
</div>
    </div> -->
  </div>
</template>

<script>
export default {
  name: 'Introduce',
  data () {
    return {
      playIconShow: true
    }
  },
  methods: {
    play () {
      this.$refs.video.play()
      this.playIconShow = false
    }
  }
}
</script>

<style lang="less" scoped>
@import url('../../../style/mixin.less');
.introduce {
display: flex;
flex-direction: column;
align-items: center;
padding-top: 100px;
.title-underline {
  .title-underline(192px);
}
.content {
  padding: 0 9px;
  margin-bottom: 29px;
  text-align: center;
}
.video {
  margin-bottom: 118px;
  position: relative;
  .cover {
position: absolute;
top: 0;
left: 0;
.icon {
  cursor: pointer;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
  }
}
}

</style>
