export const addressData = [
  {
    id: 0,
    place: 'CNSHA',
    address: '15&16F, Building 6, No.669 Tongji Road,Baoshan District, Shanghai, China',
    zipCode: '200940',
    img: require('@/assets/contact_us/shanghai.png'),
    locationStyle: 'top: 356px;left: 543px;',
    textStyle: 'top: 406px;left: 488px;',
    hoverImg: require('@/assets/contact_us/shanghai_tu.png'),
    hoverImgStyle: 'top: 230px;left: 628px;'
  },
  {
    id: 1,
    place: 'CNNBO',
    address: '16th Floor, Financial Center, No. 527 Baoshan Road, Beilun District, Ningbo',
    zipCode: '315899',
    img: require('@/assets/contact_us/ningbo.png'),
    locationStyle: 'top: 264px;left: 774px;',
    textStyle: 'top: 306px;left: 772px;',
    hoverImg: require('@/assets/contact_us/ningbo_tu.png'),
    hoverImgStyle: 'top: 143px;left: 912px;'
  },
  {
    id: 2,
    place: 'CNLYG',
    address: 'Room 1512 ~ 1513, 15th Floor, Jinhai International Building, No. 189 North haitang Road, Lianyun District, Lianyungang',
    zipCode: '222042',
    img: require('@/assets/contact_us/lianyungang.png'),
    locationStyle: 'top: 376px;left: 897px;',
    textStyle: 'top: 380px;left: 740px;',
    hoverImg: require('@/assets/contact_us/ningbo_tu.png'),
    hoverImgStyle: 'top: 230px;left: 930px;'
  },
  {
    id: 3,
    place: 'CNSHE',
    address: '8/F, Building 2A, Dabaihui Life and Health Industrial Park, No. 2002, Shenyan Road, Haishan Street, Yantian District, Shenzhen',
    zipCode: '518083',
    img: require('@/assets/contact_us/shenzhen.png'),
    locationStyle: 'top: 380px;left: 759px;',
    textStyle: 'top: 386px;left: 602px;',
    hoverImg: require('@/assets/contact_us/shenzhen_tu.png'),
    hoverImgStyle: 'top: 246px;left: 803px;'
  },
  {
    id: 4,
    place: 'CNQIN',
    address: 'Room 907-908, Free Trade Building, No. 150, East Huanghe Road, Huangdao District, Qingdao',
    zipCode: '266599',
    img: require('@/assets/contact_us/qingdao.png'),
    locationStyle: 'top: 392px;left: 731px;',
    textStyle: 'top: 439px;left: 678px;',
    hoverImg: require('@/assets/contact_us/qingdao_tu.png'),
    hoverImgStyle: 'top: 257px;left: 816px;'
  },
  {
    id: 5,
    place: 'CNTJN',
    address: 'Unit 301, 3rd Floor, Kechuang Building A, No.67 Shiyijing Road, Dawangzhuang Street, Hedong District, Tianjin',
    zipCode: '300171',
    img: require('@/assets/contact_us/tianjin.png'),
    locationStyle: 'top: 385px;left: 802px;',
    textStyle: 'top: 432px;left: 751px;',
    hoverImg: require('@/assets/contact_us/tianjin_tu.png'),
    hoverImgStyle: 'top: 256px;left: 890px;'
  },
  {
    id: 6,
    place: 'CNTCG',
    address: 'Room 1611, Joint Inspection Building, Economic Development Zone of Taicang Port, Taicang',
    zipCode: '215434',
    img: require('@/assets/contact_us/taicang.png'),
    locationStyle: 'top: 295px;left: 587px;',
    textStyle: 'top: 343px;left: 537px;',
    hoverImg: require('@/assets/contact_us/taicang_tu.png'),
    hoverImgStyle: 'top: 173px;left: 679px;'
  },
  {
    id: 7,
    place: 'CNGUA',
    address: 'Room 13A15, Lingang International Business Building, No.123, Longxue Avenue, Nansha District, Guangzhou',
    zipCode: '511464',
    img: require('@/assets/contact_us/guangzhou.png'),
    locationStyle: 'top: 374px;left: 875px;',
    textStyle: 'top: 379px;left: 914px;',
    hoverImg: require('@/assets/contact_us/guangzhou_tu.png'),
    hoverImgStyle: 'top: 304px;left: 633px;'
  }
]
