export const caseData = [
  {
    id: 1,
    title: 'Case Study - PSA',
    subtitle: 'PSA',
    title_style: { 'margin-left': '611px' },
    title_line_style: { 'margin-left': '719px' },
    content: 'International (PSA) is a leading port group and trusted partner to cargo stakeholders. PSA’s global network encompasses over 50 locations in 26 countries around the world. The Group’s portfolio comprises more than 60 deepsea, rail and inland terminals, as well as affiliated businesses in distriparks, warehouses and marine services. Through our strategic ‘customer share’ partnership, Duckbill operate all the China based logistical operations with PSA taking responsibility and servicing all the clients overseas (non China) requirements.',
    imgs: [require('@/assets/Case_Study/PSA/PSA_fengmian.png'), require('@/assets/Case_Study/PSA/01.jpg'), require('@/assets/Case_Study/PSA/02.jpg'), require('@/assets/Case_Study/PSA/03.jpg'), require('@/assets/Case_Study/PSA/04.jpg')]
  },
  {
    id: 2,
    title: 'Case Study - MAERSK',
    subtitle: 'MAERSK',
    title_style: { 'margin-left': '365px' },
    title_line_style: { 'margin-left': '474px' },
    content: 'The largest container shipping line, by utilizing our fleet network across China and our ‘Duckbill Tech’ platform, they are able to do seamlessly real-time 24/7 track & trace – total visibility!',
    imgs: [require('@/assets/Case_Study/MAERSK/Maersk_fengmian.png'), require('@/assets/Case_Study/MAERSK/01.jpg'), require('@/assets/Case_Study/MAERSK/02.jpg'), require('@/assets/Case_Study/MAERSK/03.jpg'), require('@/assets/Case_Study/MAERSK/04.jpg')]

  },
  {
    id: 3,
    title: 'Case Study - Target',
    subtitle: 'Target',
    title_style: { 'margin-left': '594px' },
    title_line_style: { 'margin-left': '704px' },
    content: 'One of the largest retailers in the USA with 1926 stores and over 350,000 employees rely on Duckbill for their ‘Quality Inspection’, ‘Container Loading Inspection’ and of course ‘Container Trucking’ services to ensure all products meet their exacting standards and arrive on time, every time!',
    imgs: [require('@/assets/Case_Study/Target/target_fengmian.png'), require('@/assets/Case_Study/Target/01.jpg'), require('@/assets/Case_Study/Target/02.jpg'), require('@/assets/Case_Study/Target/03.jpg')]
  }
]
