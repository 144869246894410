<template>
  <div
    class="item"
    :class="item.id % 2 !== 0 ? 'odd' : 'not-odd'"
    @mouseenter="mouseenterFn"
    @mouseleave="mouseleaveFn"
  >
    <div class="text" v-if="item.id % 2 !== 0">{{ item.text }}</div>
    <img :src="item.img" alt="" />
    <div class="text" v-if="item.id % 2 === 0">{{ item.text }}</div>
    <transition name="detail">
      <ItemIntroduce
        v-if="showItemIntroduce"
        :itemIntroduceSingleData="itemIntroduceSingleData"
      ></ItemIntroduce>
    </transition>
  </div>
</template>

<script>
import ItemIntroduce from './ItemIntroduce.vue'
import { itemIntroduceData } from './data'
export default {
  name: 'EXWLocalManagerIntroduceItem',
  props: ['item', 'exwLocalManagerIntroduceItemsData'],
  data () {
    return {
      itemIntroduceData,
      showItemIntroduce: false,
      itemIntroduceSingleData: {},
      itemIntroduceHeight: 0,
      height: 0
    }
  },
  components: {
    ItemIntroduce
  },
  methods: {
    mouseenterFn () {
      this.$emit('mouseenterChangeOtherImage', this.item.id)
      this.itemIntroduceData.forEach((item) => {
        if (item.id === this.item.id) {
          this.showItemIntroduce = true
          this.itemIntroduceSingleData = item
        }
      })
    },
    mouseleaveFn () {
      this.showItemIntroduce = false
    }
  }
}
</script>

<style lang="less" scoped>
.item {
  flex: 1;
  line-height: normal;
  text-align: center;
  margin: 0 10px 0 0;
  position: relative;
  &:nth-child(5) {
    margin: 0 20px;
  }
  .text {
    white-space: nowrap;
  }
  ::v-deep .introduce {
    width: 402px;
    background-color: #fff;
    transition: all 3s;
    position: absolute;
    top: 110px;
    left: -150px;
    box-shadow: 4px 4px 21px 0px rgba(57, 33, 21, 0.14);
    z-index: 999;
  }
  &.odd {
    &:nth-child(2) {
      background-color: royalblue;
      ::v-deep .introduce {
        left: -103px;
      }
    }
    &:first-child {
      ::v-deep .introduce {
        left: -30px;
        top: -300px;
      }
    }
  }
  &.not-odd {
    margin-top: 36px;
  }
  img {
    margin: 10px 0;
  }
  &:first-child {
    text-align: left;
    margin-right: 92px;
    .text {
      padding-left: 10px;
    }
  }
  &:last-child {
    margin-left: 33px;
    text-align: left;
    .text {
      padding-left: -80px;
    }
  }

  .detail-enter-active {
    transition: opacity 0.5s;
  }
  .detail-enter {
    opacity: 0;
  }
  .detail-leave-active {
    transition: opacity 0.5s;
  }
  .detail-leave-to {
    opacity: 0;
  }
}
</style>
