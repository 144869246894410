<template>
  <div class="NetWork">
    <div class="title">Service Network</div>
    <div class="title-underline"></div>
    <div>
      <img src="./服务网络覆盖图.png" style="width: 100%" alt="">
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {

    }
  },
  components: {},
  watch: {},
  mounted () {},
  methods: {}
}
</script>

<style scoped lang="less">
@import url("@/style/mixin.less");
.NetWork {
  margin-top: 130px;
  .title-underline {
    .title-underline(100px, 40px);
    margin-left: 555px;
  }
}
</style>
