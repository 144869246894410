<template>
  <div class="about-us">
        <div class="banner"></div>
    <div class="slogan">
      <div class="text">
        Trucking, Inspection & Logistics <br> Your Partner In China & Beyond…
      </div>
    </div>
        <div class="container">
          <Introduce></Introduce>
   <Story></Story>
   <!-- <div class="carousel-wrapper">
   <Carousel :imgs="imgs" :slidesPerView="3" :ifPaginationShow="false" story="story"></Carousel>
   </div> -->
   <Team></Team>
   <NetWork></NetWork>
   <Investor></Investor>
   <div class="honer">
       <div class="title">Honor Wall</div>
    <div class="title-underline"></div>
    <div class="honer-carousel">
    <Honor></Honor>
    </div>
   </div>
   <div class="partners">
       <div class="title">Our Partners</div>
    <div class="title-underline"></div>
    <div class="img-wrapper">
      <img :src="item" alt="" v-for="(item, index) in partnersData" :key="index">
    </div>
   </div>
    </div>
  </div>
</template>

<script>
import Introduce from '../EXWLocalManager/components/Introduce.vue'
import Story from './components/Story.vue'
import Investor from './components/Investor.vue'
// import Carousel from '@/components/Carousel'
import Team from './components/Team.vue'
import Honor from './components/Honor.vue'
import NetWork from './components/NetWork.vue'
import { partnersData } from './data'
import { startPagePoint, endPagePoint } from '@/api/pagePoint.js'

export default {
  name: 'AboutUs',
  components: {
    Story,
    Investor,
    // Carousel
    Team,
    Honor,
    Introduce,
    NetWork
  },
  data () {
    return {
      partnersData,
      imgs: [require('@/assets/About_us/Research-Development.png'), require('@/assets/About_us/customer service.png'), require('@/assets/About_us/inspection services.png'), require('@/assets/About_us/Professional Drivers.png')],
      id: 0
    }
  },
  async created () {
    const res = await startPagePoint({
      page: this.$route.path,
      source: 'pc'
    })
    // console.log(res)
    this.id = res.data
  },
  async beforeDestroy () {
    const res = await endPagePoint({
      id: this.id
    })
    console.log(res)
  }
}
</script>

<style lang="less" scoped>
@import url("../../style/mixin.less");
.about-us {
      line-height: 30px;
      text-align: center;
  .banner {
    .banner("~@/assets/About_us/bannner2_wuzi.png");
  }
  .slogan {
    .banner-slogan-wrap(220px);
    .text {
      text-align: left;
      background: -webkit-linear-gradient(#000, #000);
      -webkit-background-clip: text;
      -webkit-text-fill-color: #fff;
      -webkit-text-stroke:4px transparent;
      .banner-slogan(44px, #fff);
    }
  }
    .title-underline {
    .title-underline(100px);
    margin-left: 550px;
  }
  .honer {
    margin-top: 120px;
  }
  .partners {
    margin-top: 136px;
    margin-bottom: 119px;
        .title-underline {
    .title-underline(148px);
    margin-left: 562px;
  }
  .img-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    img {
      margin-bottom: 19px;
    }
  }
  }
}
.honer-carousel {
  // text-align: left;
  line-height: 25px;
}
// ::v-deep .item-swiper {
//       margin: 36px auto 52px;
//       .desc {
//         font-size: 18px;
//         color: #333333;
//         text-align: center;
//       }
//       img {
//         width: 100%;
//       }
//     }
</style>
