<template>
  <div class="story">
    <div class="title">Our Story</div>
    <div class="title-underline"></div>
    <div>
      <img src="./发展历程hover.png" style="width: 1200px;" alt="">
    </div>
    <!-- <div class="content">
      Founded in 2017, a 50-fold growth in just four years. Duckbill drivers
      transported about 20,000 containers in 2017 as the beginning,<br />
      and reached 1,100,000 containers in 2021, setting a record in the
      company's history.The company expects to reach RMB 2.3Billion (USD365
      million) in revenue this year.
    </div>
    <div class="time-line">
      <swiper :options="swiperOption" ref="mySwiper" class="swiper-wrapper">
        <swiper-slide class="slide">
          <div
            class="items-wrap"
            @mouseenter="mouseenterItems"
            @mouseleave="mouseleaveFn"
          >
            <img
              src="@/assets/About_us/kache.png"
              alt=""
              class="img"
              :class="imgLeft"
            />
            <div class="rest" :class="{ active: activeIndex !== -1 }"></div>
            <div
              :style="{ width: item.width }"
              v-for="item in timelineData1"
              :key="item.id"
              class="item-wrap"
              @mouseenter="mouseenterFn(item.id)"
            >
              <div class="big-circular" :class="{ active: activeIndex !== -1 }">
                <div class="small-circular"></div>
              </div>
              <div class="top" :style="{ width: item.width }"></div>
              <div
                class="time-item"
                :class="{ active: activeIndex !== -1 }"
                :style="{ width: item.width }"
              >
                <div class="content">
                  <div class="time">{{ item.time }}</div>
                  <div class="text" v-html="item.text"></div>
                </div>
              </div>
            </div>
            <div @click="nextPage" class="arrow" :class="{ active: activeIndex !== -1 }"></div>
          </div>
        </swiper-slide>
        <swiper-slide class="slide">
          <div
            class="items-wrap"
            @mouseenter="mouseenterItems"
            @mouseleave="mouseleaveFn"
          >
            <img
              src="@/assets/About_us/kache.png"
              alt=""
              class="img"
              :class="imgLeft"
            />
            <div class="rest" :class="{ active: activeIndex !== -1 }"></div>
            <div
              :style="{ width: item.width }"
              v-for="item in timelineData2"
              :key="item.id"
              class="item-wrap"
              @mouseenter="mouseenterFn(item.id)"
            >
              <div class="big-circular" :class="{ active: activeIndex !== -1 }">
                <div class="small-circular"></div>
              </div>
              <div class="top" :style="{ width: item.width }"></div>
              <div
                class="time-item"
                :class="{ active: activeIndex !== -1 }"
                :style="{ width: item.width }"
              >
                <div class="content">
                  <div class="time">{{ item.time }}</div>
                  <div class="text" v-html="item.text"></div>
                </div>
              </div>
            </div>
            <div @click="nextPage" class="arrow" :class="{ active: activeIndex !== -1 }"></div>
          </div>
        </swiper-slide>
        <swiper-slide class="slide">
          <div
            class="items-wrap"
            @mouseenter="mouseenterItems"
            @mouseleave="mouseleaveFn"
          >
            <img
              src="@/assets/About_us/kache.png"
              alt=""
              class="img"
              :class="imgLeft"
            />
            <div class="rest" :class="{ active: activeIndex !== -1 }"></div>
            <div
              :style="{ width: item.width }"
              v-for="item in timelineData3"
              :key="item.id"
              class="item-wrap"
              @mouseenter="mouseenterFn(item.id)"
            >
              <div class="big-circular" :class="{ active: activeIndex !== -1 }">
                <div class="small-circular"></div>
              </div>
              <div class="top" :style="{ width: item.width }"></div>
              <div
                class="time-item"
                :class="{ active: activeIndex !== -1 }"
                :style="{ width: item.width }"
              >
                <div class="content">
                  <div class="time">{{ item.time }}</div>
                  <div class="text" v-html="item.text"></div>
                </div>
              </div>
            </div>
            <div @click="nextPage" class="arrow" :class="{ active: activeIndex !== -1 }"></div>
          </div>
        </swiper-slide>
        <swiper-slide class="slide">
          <div
            class="items-wrap"
            @mouseenter="mouseenterItems"
            @mouseleave="mouseleaveFn"
          >
            <img
              src="@/assets/About_us/kache.png"
              alt=""
              class="img"
              :class="imgLeft"
            />
            <div class="rest" :class="{ active: activeIndex !== -1 }"></div>
            <div
              :style="{ width: item.width }"
              v-for="item in timelineData4"
              :key="item.id"
              class="item-wrap"
              @mouseenter="mouseenterFn(item.id)"
            >
              <div class="big-circular" :class="{ active: activeIndex !== -1 }">
                <div class="small-circular"></div>
              </div>
              <div class="top" :style="{ width: item.width }"></div>
              <div
                class="time-item"
                :class="{ active: activeIndex !== -1 }"
                :style="{ width: item.width }"
              >
                <div class="content">
                  <div class="time">{{ item.time }}</div>
                  <div class="text" v-html="item.text"></div>
                </div>
              </div>
            </div>
            <div @click="nextPage" class="arrow" :class="{ active: activeIndex !== -1 }"></div>
          </div>
        </swiper-slide>
      </swiper>
      <div
        class="swiper-pagination-story"
        slot="pagination"
        v-show="true"
      ></div>

      <div
        class="swiper-button-prev-story"
        slot="button-prev"
        v-show="false"
      ></div>
      <div
        class="swiper-button-next-story"
        ref="test"
        slot="button-next"
        v-show="false"
      ></div>
    </div> -->
  </div>
</template>

<script>
// import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import { timelineData1, timelineData2, timelineData3, timelineData4 } from './data'
export default {
  data () {
    return {
      swiperOption: {
        // loop: true, // 是否循环轮播
        // autoplay: {
        //   delay: 3000, // 是否可以自动轮播
        //   disableOnInteraction: false
        // },
        slidesPerView: 1, // 可是区域内可展示多少个块
        spaceBetween: 30, // 块之间间隔距离
        initialSlide: 0, // 默认初始显示块
        freeMode: false,
        // 显示分页
        pagination: {
          el: '.swiper-pagination-story',
          clickable: true
        },
        // 设置点击箭头
        navigation: {
          nextEl: '.swiper-button-next-story',
          prevEl: '.swiper-button-prev-story'
        }
      },
      timelineData1,
      timelineData2,
      timelineData3,
      timelineData4,
      activeIndex: -1
    }
  },
  components: {
    // Swiper,
    // SwiperSlide
  },
  computed: {
    swiper () {
      return this.$refs.mySwiper.swiper
    },
    imgLeft () {
      return `truck_${this.activeIndex}`
    }
  },
  methods: {
    nextPage () {
      console.log(this.$refs.test)
      this.$refs.test.dispatchEvent(new MouseEvent('click'))
    },
    changeColor () {},
    mouseenterFn (id) {
      this.activeIndex = id
      this.$forceUpdate()
    },
    mouseleaveFn () {
      console.log('鼠标离开')
      this.activeIndex = -1
      this.$forceUpdate()
    },
    mouseenterItems () {
      this.activeIndex = 0
      this.$forceUpdate()
    }
  }
}
</script>

<style lang="less" scoped>
@import url("~@/style/mixin.less");
.story {
  margin-top: 80px;
  margin-bottom: 80px;
  .title-underline {
    .title-underline(100px);
    margin-left: 550px;
  }
  position: relative;
  .content {
    margin-bottom: 20px;
  }
  .slide {
    text-align: left;
    position: relative;
    .items-wrap {
      padding-left: 20px;
      display: flex;
      position: relative;
      .rest {
        width: 16px;
        height: 6px;
        background-color: #666666;
        position: absolute;
        top: 41px;
        left: 6px;
        &.active {
            background-color: #ff9100;
          }
      }
      img {
        width: 76px;
        height: 30px;
        position: absolute;
        top: 4px;
        left: 45px;
        transition: all 0.8s;
        &.truck_1 {
          left: 45px;
        }
        &.truck_2 {
          left: 185px;
        }
        &.truck_3 {
          left: 385px;
        }
        &.truck_4 {
          left: 595px;
        }
        &.truck_5 {
          left: 775px;
        }
        &.truck_6 {
          left: 1005px;
        }
        &.truck_7 {
          left: 45px;
        }
        &.truck_8 {
          left: 405px;
        }
        &.truck_9 {
          left: 785px;
        }
        &.truck_10 {
          left: 1055px;
        }
        &.truck_11 {
          left: 45px;
        }
        &.truck_12 {
          left: 400px;
        }
        &.truck_13 {
          left: 720px;
        }
        &.truck_14 {
          left: 1000px;
        }
        &.truck_15 {
          left: 45px;
        }
        &.truck_16 {
          left: 400px;
        }
        &.truck_17 {
          left: 730px;
        }
        &.truck_18 {
          left: 1000px;
        }
      }
      .item-wrap {
        position: relative;
        .big-circular {
          z-index: 999;
          position: absolute;
          top: 28px;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 30px;
          height: 30px;
          border-radius: 50%;
          background-color: #666666;
          &.active {
            background-color: #ff9100;
          }
          .small-circular {
            width: 20px;
            height: 20px;
            border-radius: 50%;
            background-color: #fff;
          }
        }
        .top {
          height: 40px;
        }
        .time-item {
          padding: 15px 30px 0 0;
          border-top: 6px solid #666;
          &.active {
            border-top: 6px solid #ff9100;
          }

          .content {
            margin-left: -17px;
          }
        }
      }
    }
  }
  .swiper-button-prev-story {
    top: 61%;
    width: 36px;
    height: 36px;
    left: -55px;
    background: url("~@/assets/EXW_Local_Manager/zuo-no-hover.png") no-repeat;
    // background-size: 100% 100%;
    &::after {
      content: "";
    }
    &:hover {
      background: url("~@/assets/EXW_Local_Manager/hover_zuo.png") no-repeat;
    }
    .swiper-button-disabled {
      background: url("~@/assets/EXW_Local_Manager/zuo.png") no-repeat;
    }
  }
  .swiper-button-next-story {
    top: 61%;
    width: 36px;
    height: 36px;
    right: -55px;
    background: url("~@/assets/EXW_Local_Manager/you-no-hover.png") no-repeat;
    // background-size: 100% 100%;
    &::after {
      content: "";
    }
    &:hover {
      background: url("~@/assets/EXW_Local_Manager/you_hover.png") no-repeat;
    }
    .swiper-button-disabled {
      background: url("~@/assets/EXW_Local_Manager/you.png") no-repeat;
    }
  }

  .arrow {
    cursor:pointer;
    width: 0;
    height: 0;
    border-color: #666 transparent;
    border-width: 0 30px 19px 0;
    border-style: solid;
    position: absolute;
    top: 27px;
    right: 2px;
    &.active {
      border-color: #ff9100 transparent;
    }
  }
}
.swiper-pagination {
  position: absolute;
  left: 0;
  right: 0;
  bottom: -15px;
}
::v-deep .swiper-pagination-bullet {
  margin: 0 4px;
  width: 24px;
  height: 9px;
  background: url('~@/assets/EXW_Local_Manager/xuanzhong.png') no-repeat;
  border-radius: 0;
}
</style>
