import http from '@/utils/request'

// 进入页面
const startPagePoint = (data) => {
  return http({
    method: 'post',
    url: '/customer-consultion/startPagePoint',
    data
  })
}

// 离开页面
const endPagePoint = (data) => {
  return http({
    method: 'post',
    url: '/customer-consultion/endPagePoint',
    data
  })
}

export { startPagePoint, endPagePoint }
