<template>
  <swiper :options="swiperOption" ref="mySwiper" class="swiper-wrapper">
    <swiper-slide v-for="(item, index) in imgs" :key="index">
      <div
        v-if="service"
        class="content"
        v-html="item.content"
        :style="item.style"
      ></div>
      <div v-if="service" class="subtitle">{{ item.title }}</div>
      <img :src="service ? item.img : item" alt="" />
    </swiper-slide>
    <div class="swiper-pagination" slot="pagination"></div>
    <div class="swiper-button-prev" slot="button-prev">
      <!-- <img src="@/assets/EXW_Local_Manager/hover_zuo.png" alt=""> -->
    </div>
    <div class="swiper-button-next" slot="button-next"></div>
  </swiper>
</template>
<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import Vue from 'vue'
export default {
  props: ['imgs', 'service'],
  data () {
    return {
      swiperOption: {
        // loop: true, // 是否循环轮播
        // autoplay: {
        //   delay: 3000, // 是否可以自动轮播
        //   disableOnInteraction: false
        // },
        slidesPerView: 1, // 可是区域内可展示多少个块
        spaceBetween: 30, // 块之间间隔距离
        initialSlide: 0, // 默认初始显示块
        freeMode: false,
        // 显示分页
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        // 设置点击箭头
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        on: {
          slideChange: function () {
            // console.log(new Vue())
            console.log('改变了，activeIndex为' + this.activeIndex)
            new Vue().$emit('getIndex', this.activeIndex)
          }
        }
      }
      // imgs1: '@/assets/Case_Study/banner4.png'
    }
  },
  components: {
    Swiper,
    SwiperSlide
  },
  computed: {
    swiper () {
      return this.$refs.mySwiper.swiper
    }
  }
}
</script>

<style lang="less" scoped>
// .recommendPage .swiper-container {
//   position: relative;
//   width: 639px;
//   height: 319px;
//   padding: 0 102px;
// }
.recommendPage .swiper-container .swiper-slide {
  width: 850px;
  height: 490px;
  img {
    width: 100%;
    height: 100%;
  }
}

.swiper-pagination-bullets {
  bottom: -7px;
}
::v-deep .swiper-pagination-bullet {
  width: 24px;
  height: 9px;
  background: url("~@/assets/EXW_Local_Manager/xuanzhong.png") no-repeat;
  border-radius: 0;
}
.swiper-button-prev {
  width: 36px;
  height: 36px;
  left: 84px;
  background: url("~@/assets/EXW_Local_Manager/zuo-no-hover.png") no-repeat;
  // background-size: 100% 100%;
  &::after {
    content: "";
  }
  &:hover {
    background: url("~@/assets/EXW_Local_Manager/hover_zuo.png") no-repeat;
  }
  .swiper-button-disabled {
    background: url("~@/assets/EXW_Local_Manager/zuo.png") no-repeat;
  }
}
.swiper-button-next {
  width: 36px;
  height: 36px;
  right: 84px;
  background: url("~@/assets/EXW_Local_Manager/you-no-hover.png") no-repeat;
  // background-size: 100% 100%;
  &::after {
    content: "";
  }
  &:hover {
    background: url("~@/assets/EXW_Local_Manager/you_hover.png") no-repeat;
  }
  .swiper-button-disabled {
    background: url("~@/assets/EXW_Local_Manager/you.png") no-repeat;
  }
}
</style>
