<template>
  <div class="services">
    <div class="title">Duckbill Tech</div>
    <div class="title-underline"></div>
<Carousel :imgs="serviceData" @getIndex="getIndex" :service="true">
  <!-- <div>Location of the Truck</div>
  <div></div> -->
</Carousel>
    <!-- <div class="recommendPage">
          <swiper :options="swiperOption" ref="mySwiper" class="swiper-wrapper">
            <swiper-slide>
                <img src="@/assets/EXW_Local_Manager/Instant Quote.png" alt="">
              </swiper-slide>
            <swiper-slide><img src="@/assets/EXW_Local_Manager/Location of the Truck.png" alt=""></swiper-slide>
            <swiper-slide><img src="@/assets/EXW_Local_Manager/Order Status.png" alt=""></swiper-slide>
            <div class="swiper-pagination" slot="pagination"></div>
            <div class="swiper-button-prev" slot="button-prev"></div>
            <div class="swiper-button-next" slot="button-next"></div>
          </swiper>
        </div> -->
  </div>
</template>

<script>
// import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import Carousel from '@/components/Carousel'
import { serviceData } from './data'
// import 'swiper/css/swiper.css'
export default {
  data () {
    return {
      swiperOption: {
        loop: true, // 是否循环轮播
        autoplay: true, // 是否可以自动轮播
        slidesPerView: 1, // 可是区域内可展示多少个块
        spaceBetween: 30, // 块之间间隔距离
        initialSlide: 0, // 默认初始显示块
        freeMode: false,
        // 显示分页
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        // 设置点击箭头
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      },
      serviceData
      // imgs: [require('@/assets/EXW_Local_Manager/Instant Quote.png'), require('@/assets/EXW_Local_Manager/Location of the Truck.png'), require('@/assets/EXW_Local_Manager/Order Status.png')]
    }
  },
  computed: {
    swiper () {
      return this.$refs.mySwiper.swiper
    }
  },
  components: {
    // Swiper,
    // SwiperSlide
    Carousel
  },
  methods: {
    getIndex (index) {
      console.log(111111211)
      console.log(1111, index)
    }
  }
}
</script>

<style lang="less" scoped>
@import url("@/style/mixin.less");
.services {
  margin-top: 120px;
  text-align: center;
  padding-bottom: 120px;
  ::v-deep .subtitle {
    font-size: 24px;
    color: #ff9100;
    margin-bottom: 17px;
  }
  .title-underline {
    .title-underline(100px);
    margin-left: 562px;
  }
::v-deep .content {
  margin-bottom: 30px;
}

.swiper-wrapper {
  height: 716px;
}
::v-deep .swiper-button-prev {
  top: 63%;
}

::v-deep .swiper-button-next {
  top: 63%
}
//   .recommendPage .swiper-container {
//   position: relative;
//   width: 100%;
//   height: 490px;
// }
// .recommendPage .swiper-container .swiper-slide {
//   width: 100%;
//   line-height: 200px;
//   color: #000;
//   font-size: 16px;
//   text-align: center;
// }

// ::v-deep .swiper-button-prev {
//   background: url('~@/assets/EXW_Local_Manager/hover_zuo.png') no-repeat;
//   // background-size: 100% 100%;
//   .swiper-button-disabled {
//     background: url('~@/assets/EXW_Local_Manager/zuo.png') no-repeat;
//   }
// }
      //   .swiper{
      //   float:left;
      //   width:642px;
      //   height:400px;
      //   margin-top:5px;
      //   img{
      //     width:100%;
      //     height:100%;
      //   }
      // }
}
</style>
