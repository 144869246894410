<template>
  <div class="case">
    <div class="title" :style="item.title_style">{{ item.title }}</div>
    <div class="title-underline" :style="item.title_line_style"></div>
    <div class="content" :style="{'flex-direction': item.id % 2 !== 0 ? 'row' : 'row-reverse'}">
      <div class="context">
        <div class="subtitle">{{ item.subtitle }}</div>
        <div class="text">{{ item.content }}</div>
      </div>
      <div class="carousel" :class="{'img-left': item.id % 2 === 0 }">
        <Carousel :imgs="item.imgs">
          <!-- <img src="../../../assets/Case_Study/banner4.png" alt=""> -->
        </Carousel>
      </div>
    </div>
  </div>
</template>

<script>
import Carousel from '@/components/Carousel'
export default {
  name: 'Case',
  props: ['item'],
  components: {
    Carousel
  }
}
</script>

<style lang="less" scoped>
@import url("../../../style/mixin.less");
.case {
  margin-top: 110px;
  .title-underline {
    .title-underline(100px);
  }
  .content {
    display: flex;
    align-items: center;
    .context {
      margin-top: -31px;
      .subtitle {
        color: #ff9100;
        font-size: 24px;
        margin-bottom: 21px;
      }
      .text {
        width: 420px;
      }
    }
    .carousel {
      // width: 640px;
      overflow: hidden;
      width: calc(100% - 430px);
    }
    .img-left {
      ::v-deep .swiper-wrapper {
        padding-left: 25px;
      }
        ::v-deep .swiper-button-prev {
    left: 1px;
  }
    ::v-deep .swiper-button-next {
    right: 43px;
  }
    }
  }
  ::v-deep .swiper-wrapper {
    padding-left: 35px;
    height: 356px;
  }
  ::v-deep .swiper-button-prev {
    left: 23px;
  }
  ::v-deep .swiper-button-next {
    right: 43px;
  }
  ::v-deep .swiper-pagination-bullets {
    bottom: 7px;
  }
}
</style>
