export const timelineData1 = [
  {
    id: 1,
    time: 'JUL.2017',
    text: '>Established',
    width: '105px'
  },
  {
    id: 2,
    time: 'SEP.2017',
    text: '>Completed Angel Round',
    width: '191px'
  },
  {
    id: 3,
    time: 'DEC.2017',
    text: '>Successfully selected as the co-construction unit of Shanghai Social credit public platform',
    width: '242px'
  },
  {
    id: 4,
    time: 'JAN.2018',
    text: '>Obtain investment from Zhuopu Capital',
    width: '163px'
  },
  {
    id: 5,
    time: 'MAY.2018',
    text: '>Sep up an Employee Union<br> >The first session of “Future Truck Boss” driver’s children scholarship activity successfully held',
    width: '251px'
  },
  {
    id: 6,
    time: 'SEP.2018',
    text: '>The business of Ningbo port was launched <br> >Obtain investment from Anchi Capital, Jingya Capital, Zhuopu Capital',
    width: '216px'
  }
]
export const timelineData2 = [
  {
    id: 7,
    time: 'OCT.2018',
    text: '>The first customer conference was held to promote industry standard service',
    width: '315px'
  },
  {
    id: 8,
    time: 'NOV.2018',
    text: '>Selected as “ Shanghai Innovation Demonstration Project for Promoting Modern Shipping Service Industry” <br> >Won the “ Unicorn Night - Growth Award of the Fifth world Internet Conference”',
    width: '401px'
  },
  {
    id: 9,
    time: 'JAN.2019',
    text: '>The first Ferris Wheel Award was presented to outstanding drivers',
    width: '242px'
  },
  {
    id: 10,
    time: 'APR.2019',
    text: '>Obtain investment from Fengwang Capital',
    width: '210px'
  }
]

export const timelineData3 = [
  {
    id: 11,
    time: 'May.2019',
    text: '>The second session of “Future Truck Boss”driver’s children scholarship activity successfully held',
    width: '300px'
  },
  {
    id: 12,
    time: 'May.2019',
    text: '>Obtain investment from Xinyada Capital',
    width: '300px'
  },
  {
    id: 13,
    time: 'Jun.2019',
    text: '>Obtain investment from Fusheng Capital',
    width: '300px'
  },
  {
    id: 14,
    time: 'Jun.2020',
    text: '>Obtain investment from Future Capital, Pavilion Capital, Unicorn Capital',
    width: '268px'
  }
]
export const timelineData4 = [
  {
    id: 15,
    time: 'Jun.~Oct.2020',
    text: '>The business of Qingdao port, Taicang Port, Shenzhen port was launched',
    width: '300px'
  },
  {
    id: 16,
    time: 'Aug.2020',
    text: '>Founder Nico Tang was awarded“Top 10 Outstanding Young Entrepreneurs in Pudong New Area”',
    width: '300px'
  },
  {
    id: 17,
    time: 'Dec.2020',
    text: '>The third session of “Future Truck Boss”driver’s children scholarship activity successfully held',
    width: '300px'
  },
  {
    id: 18,
    time: 'Mar.2021',
    text: '>Completed $50 million in Series B financing“International Container Road Transport Big Data Center” was officially launched',
    width: '268px'
  }
]

export const honerData = [
  {
    id: 1,
    data: [
      'Initial co-construction units of the Shanghai Municipal Market Credit Information Public Service Platform',
      '2018 "Chuanghehui" Business School Good Project 8th Roadshow Winner Award',
      'Member of the Young Entrepreneurs',
      'Baoshan Industrial Internet Innovation Demonstration Enterprise',
      '7th China Innovation & Entrepreneurship Competition Shanghai Division',
      '"Entrepreneurship in Shanghai" International Innovation & Entrepreneurship Competition Winner Award',
      'The 7th China Innovation & Entrepreneurship Competition - Outstanding Enterprises (Start-up Group)',
      '5th Internet Conference New Economy Unicorn Night New Economy Growth Award',
      'Shanghai Science & Technology Business Incubator - 30 years of cutting-edge start-ups',
      'China Merchants Bank ‘Spread your wings and fly’ Award',
      '100 most concerned Chinese in the shipping industry in 2018 ',
      'Rock Solid Award - 2018 Rookie International Partner of the Year',
      'Top 100 - Insight into Zhangjiang',
      'Shanghai municipality - Demonstration project for modern shipping service industry'
    ],
    img: require('@/assets/Honor_Wall/18year.png')
  },
  {
    id: 2,
    data: [
      '2019 China\'s shipping industry Top Ten scientific & technological innovation enterprise',
      '2019 Transportation & Logistics Service Innovation Enterprise',
      'Most influential innovative enterprise in the field of intelligent logistics in 2019',
      '"Future Unicorn Top 30" (The 3rd Global CEO Dream Creation Ceremony)',
      'Shanghai harmonious labor relations standard enterprise',
      'Shanghai Youth Innovation & Entrepreneurship Competition - First Prize',
      '2019 "Yangtze River Delta Top Ten Intelligent Shipping Events"',
      'Shanghai\'s top 50 start-ups with the most investment potential - 2019',
      '2019 China Shipping Technology Top Ten People',
      'Most touching figure in the national international container transportation and yard industry',
      'WISE2019 Excellent Startup List "New Business Pioneer 100 List"',
      'The 100 most concerned Chinese within the shipping industry in 2019',
      'Outstanding shipping Internet platform',
      'Outstanding Commercial Vehicle Partner of the Year',
      'WuSong craftsman',
      'Wusong Street 2018 Advanced Trade Union Organization',
      '2019 "Maker China" SME Innovation & Entrepreneurship Competition Top 500 Enterprises'
    ],
    img: require('@/assets/Honor_Wall/19year.png')
  },
  {
    id: 3,
    data: [
      'Shanghai Supply Chain Innovation & Application Demonstration Enterprise Award',
      'Industry Drive Award',
      'Shanghai Credit Field (Social Credit Service) 2020 – Rated Excellent',
      'Baoshan District 2019 Annual Economic Development Growth Award',
      'Chuangbang 2020 China Top 100 - Innovative Growth Enterprise',
      'Top 50 Startups - 2020',
      'Top Ten – ‘Outstanding Young Entrepreneurs’ -  Pudong New Area',
      'International Container Road Transport Enterprise - Big Data Center',
      '"Shipping Technology Innovation Award" - Shanghai International Shipping Center Construction',
      '"Best Multimodal Transport Service Innovation Enterprise" - China Freight Forwarding Golden Wheel Award',
      '"Most Beautiful Retrograde in the Port & Shipping Logistics Industry" Best Organization Award',
      '"Pioneer Enterprise of \'Anti-epidemic & Smooth Maintenance" - Port Shipping Logistics Industry Award'
    ],
    img: require('@/assets/Honor_Wall/20year.png')
  },
  {
    id: 4,
    data: [
      '2020 -  Voted Most Promising Enterprise in the Logistics Industry - 11th China Logistics Investment and Financing Conference',
      '2020 – Award Special Contribution to Epidemic Prevention & Control Enterprise',
      'In 2021, "International Container Road Transport Enterprise Big Data Center" was listed by Duckbill',
      '2021 "China\'s Top 20 Most Investor-Concerned Innovative Enterprise" - Finance China 2020-2021',
      '2021 "Top 10 Demonstration Scenarios for Economic Digital Transformation in Baoshan District"',
      '2021 – Awraded: Baoshan District Digital Transformation Support in Baoshan District Level Demonstration Application Category',
      '2021 Dingli Support Award (Zhejiang Dahua Technology Co., Ltd.)',
      '2021 Awarded Top Ten People - Tang Hongbin - China Shipping Technology',
      '2021 Meritorious Strategic Partner (CIMC Tonghua)',
      '2021 Best Support Award (Midea Group International Logistics Platform)',
      'The 100-man group of tech unicorns – the platypus',
      'China CITIC Bank "High Value Customer" Award',
      '2021 Annual Expo "Excellent Logistics and Supply Chain Service Provider"',
      '2021 China Industrial Digitalization Practice TOP10'
    ],
    img: require('@/assets/Honor_Wall/21year.png')
  }
]

export const investmentLogo = [
  {
    id: 1,
    content: `Duckbill has received US$50 million in investment led by Singapore<br>
    state-backed investment firm Temasek Holdings. “Duckbill has integrated<br>
    demand and supply in an innovative way and has significantly improved<br>
    efficiency in the industry, getting extremely good feedback from both clients<br>
    and drivers,” said Li Zhengwei, executive director of Lighthouse Capital,<br>
    which was the dealmaker for this financing round.<br>`,
    img: require('@/assets/About_us/Pavilion.png')
  },
  {
    id: 2,
    content: `Pavilion Capital, along with existing investors Future Capital,<br>
    Shunwei Capital, Furong Capital, Zhuopu Capital, and Unicorn Capital Partners, also joined in the round.`,
    img: require('@/assets/About_us/pavilion_logo.png'),
    style: 'margin-bottom: 120px'
  },
  {
    id: 3,
    content: `The latest investment brings the total amount the company has raised to<br>
    date to nearly US$100 million.Shanghai-based, Duckbill takes up the largest<br>
    market share in the East China region and is growing rapidly in the cities of Shenzhen<br>
    and Qingdao. The fresh capital will be used for its expansion in the South China and North China markets.`,
    img: require('@/assets/About_us/futureCapital.png'),
    style: 'margin-bottom: 60px'
  },
  {
    id: 4,
    content: `Moving forward, founder and CEO Tang Hongbin said “Duckbill will further<br>
    invest in its R&D efforts and build a transportation management system”<br>
    Last year, the total volume of trade in China reached 3.2 trillion yuan (US$492.1 billion), <br>
    which boosted the container logistics market, according to Shunwei Capital, which added<br>
    that the container logistics market size has surpassed 1 trillion yuan (US$153.8 billion).`,
    img: require('@/assets/About_us/unicorn.png'),
    style: 'margin-bottom: 30px'
  }
]
