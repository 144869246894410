<template>
  <div class="investor">
    <div class="title">Our Investors</div>
        <div class="title-underline"></div>
        <div class="img-wrapper">
          <img :src="item" alt="" v-for="(item, index) in investorsData" :key="index">
        </div>
  </div>
</template>

<script>
import { investorsData } from '../data'
export default {
  components: {
    // Carousel
  },
  data () {
    return {
      investorsData,
      pavilionLogo: require('@/assets/About_us/pavilion_logo.png'),
      futureCapitalLogo: require('@/assets/About_us/futureCapital.png'),
      unicorn: require('@/assets/About_us/unicorn.png'),
      imgs: [require('@/assets/About_us/Pavilion.png'), require('@/assets/About_us/Future.png')]
    }
  }
}
</script>

<style lang="less" scoped>
@import url('~@/style/mixin.less');
.investor {
  margin-top: 80px;
  .title-underline {
  .title-underline(100px);
  margin-left: 550px;
}
.img-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    img {
      display: block;
      width: 24%;
      height: 116px;
      margin-bottom: 19px;
      margin-right: 10px;
    }
  }
}
</style>
