<template>
  <div class="case-study">
    <div class="banner"></div>
    <div class="slogan">
      <div class="text">
        <!-- Real-Time Container tracking,<br />
        <span style="line-height: 30px;">Booking & Quotations</span><br />
        <span style="font-size: 28px;font-weight: normal;line-height: 30px;">Powered By</span><br/>
        Duckbill Tec™ -->
        <p>24/7 Real-Time Container tracking,</p>
        <p>Booking & Quotations</p>
        <p style="font-size: 30px;font-weight: normal;line-height: 40px;">Powered By</p>
        <p>Duckbill Tech™</p>
      </div>
    </div>
    <div class="container">
    <div class="content">
      <Case v-for="item in caseData" :key="item.id" :item="item"></Case>
    </div>
    </div>
  </div>
</template>

<script>
import Case from './components/Case.vue'
import { caseData } from './data'
import { startPagePoint, endPagePoint } from '@/api/pagePoint.js'

export default {
  name: 'CaseStudy',
  components: {
    Case
  },
  data () {
    return {
      caseData,
      id: 0
    }
  },
  async created () {
    const res = await startPagePoint({
      page: this.$route.path,
      source: 'pc'
    })
    // console.log(res)
    this.id = res.data
  },
  async beforeDestroy () {
    const res = await endPagePoint({
      id: this.id
    })
    console.log(res)
  }
}
</script>

<style lang="less" scoped>
@import url("../../style/mixin.less");
.case-study {
  .banner {
    .banner("~@/assets/Case_Study/banner4_wuzi.png");
  }
  .slogan {
    .banner-slogan-wrap(161px);
    .text {
      .banner-slogan(44px, #fff);
      p {
        margin-bottom: 0px;
      }
    }
  }
  padding-bottom: 150px;
}
</style>
