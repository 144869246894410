<template>
  <div class="numbers">
    <div class="container">
      <div class="title">By the numbers</div>
      <div class="title-underline"></div>
      <div class="content">
        Duckbill has led the transportation, inspection and supply chain
        industry, and here are all the ways we make it happen: the number of
        trucks, drivers, facilities, inspectors, R&D — everything!
      </div>
    </div>
    <div class="infos">
      <div class="container">
        <div class="container-wrap">
        <div class="item" v-for="item in numbersData" :key="item.id">
          <div><img :src="item.imgUrl" alt="" /></div>
          <div class="number">{{ item.number }}</div>
          <div class="text">{{ item.text }}</div>
        </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { numbersData } from './data'
export default {
  data () {
    return {
      numbersData
    }
  }
}
</script>

<style lang="less" scoped>
@import url("../../../style/mixin.less");
.numbers {

  margin-top: 119px;
  .container {
    text-align: center;
    .title-underline {
      .title-underline(192px);
      margin-left: 507px;
    }
    .container-wrap {
      display: flex;
      .item {
        margin: 38px 0 33px;
        width: 239px;
        height: 173px;
        border-right: 1px solid #d7d5d3;
        &:last-child {
          border: none;
        }
      }
    }
  }
  .infos {
    width: 100%;
    height: 244px;
    background-color: #f6f6f6;
    margin-top: 25px;
    line-height: normal;
    .number {
      font-size: 36px;
      font-weight: bold;
      margin: 20px 0 25px;
    }
    .text {
      font-size: 16px;
    }
  }
}
</style>
