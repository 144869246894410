<template>
  <div class="business-consulting">
    <div class="container">
      <a-spin :spinning="spinning">
        <div class="header">
          <div class="consulting-title">Business Consulting</div>
          <div class="tips">
            Are you looking for expert advise? Fill out the form below for a quick
            email or phone response.
          </div>
        </div>
        <div class="form">
          <!-- <a-form-model layout="inline"  :model="form" :label-col="labelCol" :wrapper-col="wrapperCol"> -->
          <a-form-model
            layout="inline"
            :model="form"
            ref="ruleForm"
            :rules="rules"
          >
            <a-form-model-item
              label="Name"
              required
              prop="userName"
              ref="userName"
            >
              <a-input
                style="width: 496px;margin-bottom: 8px;"
                v-model="form.userName"
                @blur="
                  () => {
                    $refs.userName.onFieldBlur();
                  }
                "
              />
              <hr width="552" style="margin-left: -62px; margin-top: -8px" />
            </a-form-model-item>
            <a-form-model-item
              label="Company Name"
              required
              ref="companyName"
              prop="companyName"
            >
              <a-input
                v-model="form.companyName"
                style="width: 410px;margin-bottom: 8px;"
                @blur="
                  () => {
                    $refs.companyName.onFieldBlur();
                  }
                "
              />
              <hr width="520" style="margin-left: -125px; margin-top: -8px" />
            </a-form-model-item>
            <a-form-model-item
              label="Phone Number"
              required
              ref="contactMobilePhone"
              prop="contactMobilePhone"
              class="phone"
            >
              <country-code-selector
                class="country-code-selector"
                ref="country-code-selector"
                :countryCode.sync="form.remark"
                style="width: 112px"
              ></country-code-selector>
              <a-input
                type="text"
                disabled
                v-model="form.remark"
                style="width: 60px"
              />
              <a-input
                type="text"
                v-model="form.contactMobilePhone"
                style="width: 252px"
                @blur="
                  () => {
                    $refs.contactMobilePhone.onFieldBlur();
                  }
                "
              />
              <!-- <a-input-group compact>
                <a-select default-value='CHN' size="small" show-search style="width: 120px" :filter-option="filterOption" @change="handleChange">
                  <a-select-option value="+86 - " aria-selected="">
                    <img src="@/assets/EXW_Local_Manager/zhongguo.png" alt="">
                    CHN
                  </a-select-option>
                  <a-select-option value="+80 - ">
                    US
                  </a-select-option>
                </a-select>
                {{code ? code : '+86 -'}}
                <a-input style="width: 62%" v-model="form.phone" size="small"
                        @blur="
                    () => {
                      $refs.phone.onFieldBlur();
                    }
                  "
                ></a-input>
              </a-input-group> -->
              <hr width="550" style="margin-left: -562px; margin-top: 38px" />
            </a-form-model-item>
            <a-form-model-item
              label="Work Email"
              style="margin-left: 16px"
              ref="email"
              prop="email"
            >
              <a-input
                v-model="form.email"
                style="width: 410px;margin-bottom: 8px;"
                @blur="
                  () => {
                    $refs.email.onFieldBlur();
                  }
                "
              />
              <hr width="520" style="margin-left: -96px; margin-top: -8px" />
            </a-form-model-item>
            <a-form-model-item label="Inquiry">
              <a-input v-model="form.requirements" style="width: 948px;margin-bottom: 8px;" />
              <hr width="1000" style="margin-left: -54px; margin-top: -8px" />
            </a-form-model-item>
            <a-form-model-item style="margin-left: 0px">
              <a-button type="primary" @click="submit"> Submit </a-button>
            </a-form-model-item>
          </a-form-model>
        </div>
      </a-spin>
    </div>
  </div>
</template>

<script>
// import countryCodeSelector from 'vue-country-code-selector'
import countryCodeSelector from '@/components/countryCode/index.js'
// import { add } from '@/api/customerConsultion.js'
import util from '@/utils/request_two.js'
export default {
  name: 'BusinessConsulting',
  data () {
    return {
      code: '',
      spinning: false,
      form: {
        userName: '',
        contactPerson: '',
        companyName: '',
        contactMobilePhone: '',
        email: '',
        requirements: '',
        remark: 86
      },
      rules: {
        userName: [
          {
            required: true,
            message: 'Please input name',
            trigger: 'blur'
          }
        ],
        companyName: [
          {
            required: true,
            message: 'Please input company name',
            trigger: 'blur'
          }
        ],
        contactMobilePhone: [
          {
            required: true,
            message: 'Please input phone number',
            trigger: 'blur'
          }
        ],
        // 邮箱校验
        email: [
          {
            required: true,
            message: 'Please enter the correct email address',
            trigger: ['change', 'blur'],
            pattern:
              /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/
          }
        ]
      }
    }
  },
  components: {
    countryCodeSelector
  },
  methods: {
    filterOption (input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      )
    },
    handleChange (value) {
      console.log(value)
      this.code = value
    },
    submit () {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          const params = Object.assign({}, this.form, {
            remark: `区号: ${this.form.remark}`
          })
          this.spinning = true
          params.contactPerson = params.userName
          params.isOverseas = true
          util.en_customer_ajax_post(this, '/customer-consultion/add', params, res => {
            if (res.data.success === true) {
              this.spinning = false
              this.$message.success('Submitted successfully')
              this.form = {
                userName: '',
                contactPerson: '',
                companyName: '',
                contactMobilePhone: '',
                email: '',
                requirements: '',
                remark: 86
              }
            } else {
              this.spinning = false
              this.$message.error('Submitted Error')
            }
          }, errorCallback => {
            this.spinning = false
            this.$message.error('Submitted Error')
          })
          // const res = await add(
          //   Object.assign({}, this.form, {
          //     remark: `区号: ${this.form.remark}`
          //   })
          // )
          // console.log(res)
          // if (res.success === true) {
          //   this.$message.success('Submitted successfully')
          // }
        } else {
          return false
        }
      })
    }
  }
}
</script>
<style>
.flag-list-box {
  width: 370px !important;
  background: #fff;
}
</style>
<style lang="less" scoped>
.business-consulting {
  height: 230px;
  font-size: 14px;
  color: #060606;
  background-color: #fff;
  box-shadow: 2px 10px 20px 0px rgba(144, 144, 144, 0.07);
  .container {
    padding: 30px 30px 40px;
    .header {
      display: flex;
      .consulting-title {
        font-size: 18px;
        font-weight: bold;
        margin-right: 41px;
      }
    }
    .form {
      text-align: left;
      .ant-input {
        width: 508px;
        margin-right: 10px;
        border: none;
        &:focus {
          box-shadow: none;
        }
      }
      ::v-deep .ant-form-item-control.has-error {
        height: 30px;
      }

      ::v-deep .ant-select-open .ant-select-selection {
        box-shadow: none;
      }
      ::v-deep
        .ant-input-group.ant-input-group-compact
        > .ant-select
        > .ant-select-selection {
        border: none;
      }
      ::v-deep .ant-select-selection {
        border: none;
      }
      ::v-deep .ant-form-item-label > label::after {
        content: "";
      }
      ::v-deep .ant-form-item-label > label {
        color: #060606;
      }
      hr {
        border-color: #f0f3f5;
        z-index: 999;
      }
    }
  }
}

.phone {
  ::v-deep .ant-form-item-children {
    display: flex;
    align-items: center;
    height: 39px;
    .country-list {
      background-color: #fff;
    }
  }
  ::v-deep .ant-form-explain {
    margin-left: 178px;
  }
}
</style>
