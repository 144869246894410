<template>
  <swiper :options="swiperOption" ref="mySwiper" class="swiper-wrapper">
    <swiper-slide v-for="(item) in honerData" :key="item.id">
      <div class="left">
         <div v-for="(honer, index) in item.data" :key="index">{{honer}}</div>
      </div>
      <div class="right">
        <span><img :src="item.img" alt=""></span>
      </div>
    </swiper-slide>
    <div class="swiper-pagination" slot="pagination"></div>
    <div class="swiper-button-prev" slot="button-prev">
      <!-- <img src="@/assets/EXW_Local_Manager/hover_zuo.png" alt=""> -->
    </div>
    <div class="swiper-button-next" slot="button-next"></div>
  </swiper>
</template>
<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import { honerData } from './data'
export default {
  props: ['imgs', 'service'],
  data () {
    return {
      swiperOption: {
        // loop: true, // 是否循环轮播
        // autoplay: {
        //   delay: 3000, // 是否可以自动轮播
        //   disableOnInteraction: false
        // },
        slidesPerView: 1, // 可是区域内可展示多少个块
        spaceBetween: 40, // 块之间间隔距离
        initialSlide: 0, // 默认初始显示块
        freeMode: false,
        // 显示分页
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        // 设置点击箭头
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      },
      honerData
      // imgs1: '@/assets/Case_Study/banner4.png'
    }
  },
  components: {
    Swiper,
    SwiperSlide
  },
  computed: {
    swiper () {
      return this.$refs.mySwiper.swiper
    }
  }
}
</script>
<style lang="less" scoped>
// .recommendPage .swiper-container {
//   position: relative;
//   width: 639px;
//   height: 319px;
//   padding: 0 102px;
// }
.swiper-container{
  width: 1138px;
  height: 464px;
}
.swiper-slide {
  text-align: left;
  padding-left: 108px;
}
.swiper-pagination-bullets {
  bottom: -7px;
}
::v-deep .swiper-pagination-bullet {
  width: 24px;
  height: 9px;
  background: url('~@/assets/EXW_Local_Manager/xuanzhong.png') no-repeat;
  border-radius: 0;
}
.swiper-button-prev {
  width: 36px;
  height: 36px;
  // left: 84px;
  background: url('~@/assets/EXW_Local_Manager/zuo-no-hover.png') no-repeat;
  // background-size: 100% 100%;
  &::after {
    content: ''
  }
  &:hover {
    background: url('~@/assets/EXW_Local_Manager/hover_zuo.png') no-repeat;
  }
  .swiper-button-disabled {
    background: url('~@/assets/EXW_Local_Manager/zuo.png') no-repeat;
  }
}
.swiper-button-next {
  width: 36px;
  height: 36px;
  // right: 84px;
  background: url('~@/assets/EXW_Local_Manager/you-no-hover.png') no-repeat;
  // background-size: 100% 100%;
  &::after {
    content: ''
  }
  &:hover {
    background: url('~@/assets/EXW_Local_Manager/you_hover.png') no-repeat;
  }
  .swiper-button-disabled {
    background: url('~@/assets/EXW_Local_Manager/you.png') no-repeat;
  }
}
.left,.right{
  display: inline-block;
  width: 66%;
  float: left;
}
.right{
  width: 34%;
  height: 80%;
  display: table;
  vertical-align: middle;
}
.right span{
  display: table-cell;
  vertical-align: middle;
}
.right img{
  max-width: 80%;
  max-height: 80%;
  display: block;
  margin: 0 auto;
}
</style>
