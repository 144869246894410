var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"business-consulting"},[_c('div',{staticClass:"container"},[_c('a-spin',{attrs:{"spinning":_vm.spinning}},[_c('div',{staticClass:"header"},[_c('div',{staticClass:"consulting-title"},[_vm._v("Business Consulting")]),_c('div',{staticClass:"tips"},[_vm._v(" Are you looking for expert advise? Fill out the form below for a quick email or phone response. ")])]),_c('div',{staticClass:"form"},[_c('a-form-model',{ref:"ruleForm",attrs:{"layout":"inline","model":_vm.form,"rules":_vm.rules}},[_c('a-form-model-item',{ref:"userName",attrs:{"label":"Name","required":"","prop":"userName"}},[_c('a-input',{staticStyle:{"width":"496px","margin-bottom":"8px"},on:{"blur":function () {
                  _vm.$refs.userName.onFieldBlur();
                }},model:{value:(_vm.form.userName),callback:function ($$v) {_vm.$set(_vm.form, "userName", $$v)},expression:"form.userName"}}),_c('hr',{staticStyle:{"margin-left":"-62px","margin-top":"-8px"},attrs:{"width":"552"}})],1),_c('a-form-model-item',{ref:"companyName",attrs:{"label":"Company Name","required":"","prop":"companyName"}},[_c('a-input',{staticStyle:{"width":"410px","margin-bottom":"8px"},on:{"blur":function () {
                  _vm.$refs.companyName.onFieldBlur();
                }},model:{value:(_vm.form.companyName),callback:function ($$v) {_vm.$set(_vm.form, "companyName", $$v)},expression:"form.companyName"}}),_c('hr',{staticStyle:{"margin-left":"-125px","margin-top":"-8px"},attrs:{"width":"520"}})],1),_c('a-form-model-item',{ref:"contactMobilePhone",staticClass:"phone",attrs:{"label":"Phone Number","required":"","prop":"contactMobilePhone"}},[_c('country-code-selector',{ref:"country-code-selector",staticClass:"country-code-selector",staticStyle:{"width":"112px"},attrs:{"countryCode":_vm.form.remark},on:{"update:countryCode":function($event){return _vm.$set(_vm.form, "remark", $event)},"update:country-code":function($event){return _vm.$set(_vm.form, "remark", $event)}}}),_c('a-input',{staticStyle:{"width":"60px"},attrs:{"type":"text","disabled":""},model:{value:(_vm.form.remark),callback:function ($$v) {_vm.$set(_vm.form, "remark", $$v)},expression:"form.remark"}}),_c('a-input',{staticStyle:{"width":"252px"},attrs:{"type":"text"},on:{"blur":function () {
                  _vm.$refs.contactMobilePhone.onFieldBlur();
                }},model:{value:(_vm.form.contactMobilePhone),callback:function ($$v) {_vm.$set(_vm.form, "contactMobilePhone", $$v)},expression:"form.contactMobilePhone"}}),_c('hr',{staticStyle:{"margin-left":"-562px","margin-top":"38px"},attrs:{"width":"550"}})],1),_c('a-form-model-item',{ref:"email",staticStyle:{"margin-left":"16px"},attrs:{"label":"Work Email","prop":"email"}},[_c('a-input',{staticStyle:{"width":"410px","margin-bottom":"8px"},on:{"blur":function () {
                  _vm.$refs.email.onFieldBlur();
                }},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}}),_c('hr',{staticStyle:{"margin-left":"-96px","margin-top":"-8px"},attrs:{"width":"520"}})],1),_c('a-form-model-item',{attrs:{"label":"Inquiry"}},[_c('a-input',{staticStyle:{"width":"948px","margin-bottom":"8px"},model:{value:(_vm.form.requirements),callback:function ($$v) {_vm.$set(_vm.form, "requirements", $$v)},expression:"form.requirements"}}),_c('hr',{staticStyle:{"margin-left":"-54px","margin-top":"-8px"},attrs:{"width":"1000"}})],1),_c('a-form-model-item',{staticStyle:{"margin-left":"0px"}},[_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.submit}},[_vm._v(" Submit ")])],1)],1)],1)])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }