<template>
  <div class="team">
    <div class="title">Our Team</div>
    <div class="title-underline"></div>
    <swiper :options="swiperOption" ref="mySwiper" class="swiper-wrapper">
      <swiper-slide>
        <div
          style="border: 1px solid #ccc;"
          @mouseenter="img1HoverInfo = true"
        >
          <img style="width: 292px;height: 170.96px;" src="@/assets/About_us/Research-Development.png" alt="" />
          <div class="info">
            <div class="info-title">
              <img src="@/assets/About_us/icon1.png" alt="" />
              <span>R & D</span>
            </div>
            <div class="text" style="cursor:pointer;" @click="img1HoverInfo = true">
              <!-- ‘Duckbill Tech’ is our state of the art, purpose built digital... -->
              100+ R&D personnel,US$3 million R&D investment annually...
              <span class="learn-more">Learn more</span>
            </div>
          </div>
        </div>
        <div class="img-hover" v-show="img1HoverInfo"  @mouseleave="img1HoverInfo = false">
          <img src="@/assets/About_us/hover1.png" alt="" />
          <!-- Research & Development -->
          <div class="title-hover">R & D</div>
          <div class="context" >
            ->&nbsp;&nbsp;&nbsp;100+ R&D personnel <br>
            ->&nbsp;&nbsp;&nbsp;US$3 million R&D investment annually <br>
            ->&nbsp;&nbsp;&nbsp;10+ intellectual property rights output annually <br>
            ->&nbsp;&nbsp;&nbsp;Hit ‘High-tech Enterprise’ in 2019 <br>
          </div>
        </div>
      </swiper-slide>
      <swiper-slide>
        <div
        style="border: 1px solid #ccc;"
          @mouseenter="img2HoverInfo = true"
        >
          <img style="width: 292px;"  src="@/assets/About_us/customer service.png" alt="" />
          <div class="info">
            <div class="info-title">
              <img src="@/assets/About_us/icon2.png" alt="" />
              <span>Customer Service</span>
            </div>
            <div class="text">
              24/7 online customer service,Inquiry&booking, order...
              <span class="learn-more">Learn more</span>
            </div>
          </div>
        </div>
        <div class="img-hover" @mouseleave="img2HoverInfo = false" v-show="img2HoverInfo">
          <img src="@/assets/About_us/kefu_hover.png" alt="" />
          <div class="title-hover">Customer Service</div>
          <div class="context">
            ->&nbsp;&nbsp;&nbsp;24/7 online customer service <br>
            ->&nbsp;&nbsp;&nbsp;Inquiry&booking, order tracking, emergency assistance, payment&billing
          </div>
        </div>
      </swiper-slide>
      <swiper-slide>
        <div
          @mouseenter="img3HoverInfo = true"
          style="border: 1px solid #ccc;"
        >
          <img style="width: 292px;" src="@/assets/About_us/inspection services.png" alt="" />
          <div class="info">
            <div class="info-title">
              <img src="@/assets/About_us/icon3.png" alt="" />
              <span>Inspection Services</span>
            </div>
            <div class="text">
              100+ inspectors(ex SGS),Appearance checking, Quantity checking....
              <span class="learn-more">Learn more</span>
            </div>
          </div>
        </div>
        <div class="img-hover" v-show="img3HoverInfo" @mouseleave="img3HoverInfo = false">
          <img src="@/assets/About_us/siji_hover.png" alt="" />
          <div class="title-hover">Inspection Service</div>
          <div class="context">
            ->&nbsp;&nbsp;&nbsp;100+ inspectors(ex SGS) <br>
            ->&nbsp;&nbsp;&nbsp;Appearance checking, Quantity checking, Function testing, Packing checking, etc. <br>
            ->&nbsp;&nbsp;&nbsp;Professional inspection report <br>
          </div>
        </div>
      </swiper-slide>
      <swiper-slide>
        <div
          @mouseenter="img4HoverInfo = true"
          style="border: 1px solid #ccc;"
        >
          <img style="width: 292px;" src="@/assets/About_us/Professional Drivers.png" alt="" />
          <div class="info">
            <div class="info-title">
              <img src="@/assets/About_us/icon4.png" alt="" />
              <span>Professional Drivers</span>
            </div>
            <div class="text">
              18,000+ registered drivers...
              <span class="learn-more">Learn more</span>
            </div>
          </div>
        </div>
                <div class="img-hover" v-show="img4HoverInfo"  @mouseleave="img4HoverInfo = false">
          <img src="@/assets/About_us/hover3.png" alt="" />
          <div class="title-hover">Professional Drivers</div>
          <div class="context">
            ->&nbsp;&nbsp;&nbsp;18,000+ registered drivers, 13,000+ active drivers
          </div>
        </div>

      </swiper-slide>
    </swiper>
    <!-- <div class="swiper-pagination p1" slot="pagination" v-show="false"></div>

    <div class="swiper-button-prev prev1" slot="button-prev"></div>
    <div class="swiper-button-next next1" slot="button-next"></div> -->
  </div>
</template>
<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import Vue from 'vue'
export default {
  props: ['imgs'],
  data () {
    return {
      swiperOption: {
        // loop: true, // 是否循环轮播
        // autoplay: {
        //   delay: 3000, // 是否可以自动轮播
        //   disableOnInteraction: false
        // },
        slidesPerView: 4, // 可是区域内可展示多少个块
        spaceBetween: 10, // 块之间间隔距离
        initialSlide: 0, // 默认初始显示块
        freeMode: false,
        // 显示分页
        pagination: {
          el: '.swiper-pagination.p1',
          clickable: true
        },
        // 设置点击箭头
        navigation: {
          nextEl: '.swiper-button-next.next1',
          prevEl: '.swiper-button-prev.prev1'
        },
        on: {
          slideChange: function () {
            // console.log(new Vue())
            console.log('改变了，activeIndex为' + this.activeIndex)
            new Vue().$emit('getIndex', this.activeIndex)
          }
        }
      },
      img1HoverInfo: false,
      img2HoverInfo: false,
      img3HoverInfo: false,
      img4HoverInfo: false
      // imgs1: '@/assets/Case_Study/banner4.png'
    }
  },
  created () {
    console.log(this)
    console.log(this.imgs)
  },
  components: {
    Swiper,
    SwiperSlide
  },
  computed: {
    swiper () {
      return this.$refs.mySwiper.swiper
    }
  },
  methods: {}
}
</script>

<style lang="less" scoped>
@import url("@/style/mixin.less");
.team {
  .title-underline {
    .title-underline(100px, 40px);
    margin-left: 555px;
  }
  position: relative;
  .info {
    text-align: left;
    position: relative;
    height: 240px;
    // background-color: pink;;
    color: #333;
    // margin-top: 14px;
    padding: 14px 10px 10px 10px;
    .info-title {
      font-size: 18px;
      img {
        margin-right: 10px;
      }
    }
    .text {
      font-size: 14px;
      margin-top: 5px;
      .learn-more {
        right: 17px;
        bottom: 10px;
        position: absolute;
        background-color: #ff9100;
        color: #fff;
        padding:0 6px;
      }
    }
  }
}
.recommendPage .swiper-container .swiper-slide {
  width: 850px;
  height: 490px;
  position: relative;
  img {
    width: 100%;
    height: 100%;
  }
}
.img-hover {
  width: 292px;
  height: 424px;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #ff9100;
  color: #fff;
  font-size: 14px;
  padding: 33px;
  padding-top: 18px;
  text-align: center;
  line-height: 20px;
  z-index: 99;
  .title-hover {
    font-size: 22px;
    margin-top: 6px;
    margin-bottom: 10px;
  }
  .context {
    font-size: 16px;
    line-height: 28px;
    text-align: left;
    // width: 424px;
  }
}

.swiper-pagination-bullets {
  bottom: -7px;
}
::v-deep .swiper-pagination-bullet {
  width: 24px;
  height: 9px;
  background: url("~@/assets/EXW_Local_Manager/xuanzhong.png") no-repeat;
  border-radius: 0;
}
.swiper-button-prev {
  top: 61%;
  width: 36px;
  height: 36px;
  left: -55px;
  background: url("~@/assets/EXW_Local_Manager/zuo-no-hover.png") no-repeat;
  // background-size: 100% 100%;
  &::after {
    content: "";
  }
  &:hover {
    background: url("~@/assets/EXW_Local_Manager/hover_zuo.png") no-repeat;
  }
  .swiper-button-disabled {
    background: url("~@/assets/EXW_Local_Manager/zuo.png") no-repeat;
  }
}
.swiper-button-next {
  top: 61%;
  width: 36px;
  height: 36px;
  right: -55px;
  background: url("~@/assets/EXW_Local_Manager/you-no-hover.png") no-repeat;
  // background-size: 100% 100%;
  &::after {
    content: "";
  }
  &:hover {
    background: url("~@/assets/EXW_Local_Manager/you_hover.png") no-repeat;
  }
  .swiper-button-disabled {
    background: url("~@/assets/EXW_Local_Manager/you.png") no-repeat;
  }
}
</style>
