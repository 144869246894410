<template>
  <div class="introduce" ref="itemIntroduceRef" :style="style">
    <img :src="itemIntroduceSingleData.imgUrl" style="width: 402px;height: 192px;" alt="" ref="imgRef"/>
    <div class="content">
      <div class="content-title" :class="itemIntroduceSingleData.subtitle ? '': 'have-subtitle-title'">{{ itemIntroduceSingleData.title }}</div>
      <div class="subtitle" :class="itemIntroduceSingleData.subtitle ? '': 'haveSubtitle'">{{ itemIntroduceSingleData.subtitle }}</div>
      <div class="text" v-html="itemIntroduceSingleData.text"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['itemIntroduceSingleData'],
  data () {
    return {
      height: 0,
      style: ''
    }
  },
  created () {
    this.$nextTick(() => {
      this.height = this.$refs.itemIntroduceRef.offsetHeight
      // console.log(this.height)
      if (this.itemIntroduceSingleData.id % 2 !== 0 && this.itemIntroduceSingleData.id !== 1) {
        if (this.height < 192) {
          this.style = `margin-top: -${this.height + 265}px`
        } else {
          this.style = `margin-top: -${this.height + 85}px`
        }
      }
    })
  }
}
</script>

<style lang="less" scoped>
.introduce {
  text-align: left;
  background-color: #fff;
  font-size: 15px;
  color: #333;
  .content {
    padding: 18px 18px 25px;
    .content-title {
      font-size: 22px;
      color: #ff9100;
    }
    .have-subtitle-title {
      margin-bottom: 9px;
    }
    .subtitle {
      margin: 9px 0 19px;
      color: #ff9100;
    }
    .haveSubtitle {
      margin: 0;
    }
    .text {
      line-height: 22px;
    }
  }
}
</style>
