<template>
  <div style="width: 100%;height: 100%;background:#fdebd1">
    <div class="submitBox" v-if="!isOk">
      <div class="box">
        <p>咨询</p>
        <a-input ref="email" v-model="email" placeholder="请输入邮箱" style="width: 90%;margin: 0 0 20px 20px;border-radius: 20px;" size="large">
          <a-icon slot="prefix" type="mail" />
        </a-input>
        <a-button @click="submit" style="width: 92%;margin-left: 4%;height: 4rem;border-radius: 6px;font-size: 1.6rem;" type="primary" block>提交</a-button>
      </div>
    </div>

    <div v-if="isOk" style="width: 100%;">
      <swiper class="swiper" :options="swiperOption">
        <swiper-slide><img src="../../assets/PosterCN/01-02.png" style="max-width:100%;display: block;" alt=""></swiper-slide>
        <swiper-slide><img src="../../assets/PosterCN/03-04.png" style="max-width:100%;display: block;" alt=""></swiper-slide>
        <swiper-slide><img src="../../assets/PosterCN/05-06.png" style="max-width:100%;display: block;" alt=""></swiper-slide>
        <swiper-slide><img src="../../assets/PosterCN/07-08.png" style="max-width:100%;display: block;" alt=""></swiper-slide>
        <div class="swiper-pagination" style="background: rgba(0, 0, 0, 0.2);bottom: 30px;position: fixed;width: 100px;left: 50%;transform: translateX(-50%);border-radius: 14px;" slot="pagination"></div>
        <!-- <div class="swiper-pagination" style="transform: translateY(-30px);" slot="pagination"></div> -->
      </swiper>
    </div>
  </div>
</template>

<script>
import util from '@/utils/request_two.js'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
export default {
  data () {
    return {
      isOk: false,
      email: '',
      swiperOption: {
        effect: 'creative',
        loop: true,
        pagination: {
          el: '.swiper-pagination'
        }
      }
    }
  },
  components: {
    Swiper,
    SwiperSlide
  },
  created () {
    document.title = '鸭嘴兽'
    if (window.localStorage.getItem('posterCN-Email')) {
      this.isOk = true
    }
  },
  methods: {
    submit () {
      const reg = /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/
      if (this.email) {
        if (!reg.test(this.email)) {
          this.$message.error('提交失败，请检查邮箱格式是否正确！')
        } else {
          util.en_customer_ajax_post(this, '/customer-consultion/add-e', { email: this.email }, res => {
            console.log(res)
            if (res.data.success) {
              window.localStorage.setItem('posterCN-Email', this.email)
              this.$message.success('提交成功')
              this.isOk = true
            }
          }, errorCallback => {
            this.$message.error('提交失败，请检查网络')
          })
        }
      } else {
        this.$message.error('提交失败，请提供正确的邮箱地址！')
      }
    }
  }
}
</script>

<style lang="less">
.submitBox{
  width: 100%;
  height: 100%;
  background: url(../../assets/PosterCN/sbbg.png) no-repeat;
  background-size: cover;
  position: relative;
  .box{
    position: absolute;
    width: 86%;
    height: 16rem;
    background: #fff;
    left: 7%;
    bottom: 8rem;
    border-radius: 12px;
    p{
      margin: 20px;
      color: #999;
      font-size: 1.4rem;
    }
  }
}
.swiper{
  --swiper-pagination-color: #55af32;/* 两种都可以 */
}
</style>
