/* const honorData */

export const partnersData = [
  require('@/assets/About_us/maersk.png'),
  require('@/assets/About_us/matson.png'),
  require('@/assets/About_us/oocl.png'),
  require('@/assets/About_us//MOl.png'),
  require('@/assets/About_us/target.png'),
  require('@/assets/About_us/173.png'),
  require('@/assets/About_us/Toll.png'),
  require('@/assets/About_us/dimerco.png'),
  require('@/assets/About_us/adp.png'),
  require('@/assets/About_us/DTW.png'),
  require('@/assets/About_us/amass.png'),
  require('@/assets/About_us/cts.png'),
  require('@/assets/About_us/PSA.png'),
  require('@/assets/About_us/meidi.png'),
  require('@/assets/About_us/gujia.png'),
  require('@/assets/About_us/NAGEL.png'),
  require('@/assets/About_us/CIMC.png'),
  require('@/assets/About_us/PICC.png'),
  require('@/assets/About_us/geodis.png'),
  require('@/assets/About_us/APL.png')
]

export const investorsData = [
  /* '../../assets/Investors/mingshi.png' */
  require('@/assets/Investors/mingshi.png'),
  require('@/assets/Investors/组 183.png'),
  require('@/assets/Investors/组 184.png'),
  require('@/assets/Investors/组 185.png'),
  require('@/assets/Investors/组 186.png'),
  require('@/assets/Investors/组 187.png'),
  require('@/assets/Investors/组 188.png'),
  require('@/assets/Investors/组 189.png'),
  require('@/assets/Investors/组 190.png'),
  require('@/assets/Investors/组 191.png'),
  require('@/assets/Investors/组 192.png')
]

export const teamHoverData = [
  {
    id: 1

  }
]
