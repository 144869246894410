export const exwLocalManagerIntroduceItemsData = [
  {
    id: 1,
    text: 'Suppliers',
    img: require('@/assets/EXW_Local_Manager/icon/suppliers/suppliers.png'),
    imgUrl: require('@/assets/EXW_Local_Manager/icon/suppliers/suppliers.png'),
    activeImg: require('@/assets/EXW_Local_Manager/icon/suppliers/suppliers_xuanzhong.png'),
    deactiveImg: require('@/assets/EXW_Local_Manager/icon/suppliers/suppliers_wei.png')

  },
  {
    id: 2,
    text: 'Quality Inspection',
    img: require('@/assets/EXW_Local_Manager/icon/Quality Inspection/Quality Inspection.png'),
    imgUrl: require('@/assets/EXW_Local_Manager/icon/Quality Inspection/Quality Inspection.png'),
    activeImg: require('@/assets/EXW_Local_Manager/icon/Quality Inspection/Quality Inspection_xuanzhong.png'),
    deactiveImg: require('@/assets/EXW_Local_Manager/icon/Quality Inspection/Quality Inspection_wei.png')

  },
  {
    id: 3,
    text: 'Container Loading Supervision',
    img: require('@/assets/EXW_Local_Manager/icon/Container Loading Supervision/Container Loading Supervision.png'),
    imgUrl: require('@/assets/EXW_Local_Manager/icon/Container Loading Supervision/Container Loading Supervision.png'),
    activeImg: require('@/assets/EXW_Local_Manager/icon/Container Loading Supervision/Container Loading Supervision_xuanzhong.png'),
    deactiveImg: require('@/assets/EXW_Local_Manager/icon/Container Loading Supervision/Container Loading Supervision_wei.png')

  },
  {
    id: 4,
    text: 'Trucking Service',
    img: require('@/assets/EXW_Local_Manager/icon/trucking/trucking.png'),
    imgUrl: require('@/assets/EXW_Local_Manager/icon/trucking/trucking.png'),
    activeImg: require('@/assets/EXW_Local_Manager/icon/trucking/trucking_xuanzhong.png'),
    deactiveImg: require('@/assets/EXW_Local_Manager/icon/trucking/trucking_wei.png')

  },
  {
    id: 5,
    text: 'Port Affairs Handling',
    img: require('@/assets/EXW_Local_Manager/icon/Port affairs handling/Port affairs handling.png'),
    imgUrl: require('@/assets/EXW_Local_Manager/icon/Port affairs handling/Port affairs handling.png'),
    activeImg: require('@/assets/EXW_Local_Manager/icon/Port affairs handling/Port affairs handling_xuanzhong.png'),
    deactiveImg: require('@/assets/EXW_Local_Manager/icon/Port affairs handling/Port affairs handling_wei.png')

  },
  {
    id: 6,
    text: 'Shipping Agent Service',
    img: require('@/assets/EXW_Local_Manager/icon/shipping agent/shipping agent.png'),
    imgUrl: require('@/assets/EXW_Local_Manager/icon/shipping agent/shipping agent.png'),
    activeImg: require('@/assets/EXW_Local_Manager/icon/shipping agent/shipping agent_xuanzhong.png'),
    deactiveImg: require('@/assets/EXW_Local_Manager/icon/shipping agent/shipping agent_wei.png')

  },
  {
    id: 7,
    text: 'Customers',
    img: require('@/assets/EXW_Local_Manager/icon/Customers/Customers.png'),
    imgUrl: require('@/assets/EXW_Local_Manager/icon/Customers/Customers.png'),
    activeImg: require('@/assets/EXW_Local_Manager/icon/Customers/Customers_xuanzhong.png'),
    deactiveImg: require('@/assets/EXW_Local_Manager/icon/Customers/Customers_wei.png')
  }
]

export const numbersData = [
  {
    id: 1,
    imgUrl: require('@/assets/EXW_Local_Manager/numbers1.png'),
    // hoverImg: require('@/assets/EXW_Local_Manager/')
    number: '16,000',
    text: 'Locations'
  },
  {
    id: 2,
    imgUrl: require('@/assets/EXW_Local_Manager/numbers2.png'),
    number: '2100+',
    text: 'Trucks'
  },
  {
    id: 3,
    imgUrl: require('@/assets/EXW_Local_Manager/numbers3.png'),
    number: '100+',
    text: 'Inspectors'
  },
  {
    id: 4,
    imgUrl: require('@/assets/EXW_Local_Manager/numbers4.png'),
    number: '18,000+',
    text: 'Drivers'
  },
  {
    id: 5,
    imgUrl: require('@/assets/EXW_Local_Manager/numbers5.png'),
    number: '100+',
    text: 'R&D'
  }
]

export const itemIntroduceData = [
//   {
//     id: 1,
//     imgUrl: require(),
//     title:
// subtitle:
// text:
//   },
  {
    id: 1,
    imgUrl: require('@/assets/EXW_Local_Manager/Suppliers.png'),
    title: 'Suppliers',
    subtitle: '',
    text: 'We collect from your source of supply in China, whether it be the actual factory, trading company or as specified and directed by you the client.'
  },
  {
    id: 2,
    imgUrl: require('@/assets/EXW_Local_Manager/Quality Inspection.png'),
    title: 'Quality Inspection',
    subtitle: 'We offer a bespoke range of Inspection services including but not limited to some of the following:',
    text: '> Quantity: Verify actual quantities shipped & loaded<br>> Packing: Check packing, labeling & shipping marks<br>> Product: Validate colors, styles, labels & packaging<br>> Quality: Examine product workmanship & finishing<br>> Functionality: Ensure fit for purpose & dependable<br>> Dimensions: Measure & confirm product/carton sizes<br>> Other: As specified and required by client'
  },
  {
    id: 3,
    imgUrl: require('@/assets/EXW_Local_Manager/Container Loading Supervision.png'),
    title: 'Container Loading Supervision',
    subtitle: 'Our service ensures your cargo is loaded and stacked securely without damage & quantity confirmed. We can also record & report on your specific criteria.',
    text: '> Record container loading conditions & times<br>> Inspect packing shipping mark, dimensions & weight<br>> Verify quantity & condition of loaded cargo<br>> Confirm product - random drawn inspection process<br>> Photographic report of container loading process<br>> Report container seal and truck license numbers'
  },
  {
    id: 4,
    imgUrl: require('@/assets/EXW_Local_Manager/Trucking Service.png'),
    title: 'Trucking Service',
    subtitle: '',
    text: 'We operate China’s largest container trucking fleet,with over 15,000 professional drivers across the country combined with our own capacity of 2000+ trucks, covering the 9 main ports in China. Duckbill provide Product Liability Insurance up to 5 Million RMB for each shipment to ensure safety and peace of mind.'
  },
  {
    id: 5,
    imgUrl: require('@/assets/EXW_Local_Manager/Port Affairs Handling.png'),
    title: 'Port Affairs Handling',
    subtitle: '',
    text: 'Service including Pick-up container, Release container...<br>Warehousing, Consolidation...'
  },
  {
    id: 6,
    imgUrl: require('@/assets/EXW_Local_Manager/Shipping Agent Service.png'),
    title: 'Shipping Agent Service',
    subtitle: '',
    text: '> Issue shipping documents as packing list, the loading manifest, VGM&CLP<br>> Arrange commercial inspections<br>> Custom Clearance.'
  },
  {
    id: 7,
    imgUrl: require('@/assets/EXW_Local_Manager/customer.jpg'),
    title: 'Oversea Customer',
    subtitle: '',
    text: ''
  }
]

export const serviceData = [
  {
    id: 1,
    title: 'Track & Trace',
    content: 'Duckbill Tech™: Complete freight visibility with 24/7 tracking and notifications <br> Our digital platform, excels at visibility and control. Powered by our propietary Duckbill Tech™, we use machine learning to become continually smarter at sourcing/locating capacity. Shippers have the option of spot-market pricing or contract rates, and carriers communicate via our Duckbill Driver™ driver app. Fluid integration with external systems makes it easy to post, negotiate, assign and track loads.',
    img: require('@/assets/EXW_Local_Manager/Location of the Truck.png'),
    style: ''
  },
  {
    id: 2,
    title: 'Instant Quote',
    content: 'Input a pickup address to query trucking prices for ports of Shanghai, Ningbo, Qingdao, Taicang, Shenzhen......<br>all prices displayed are valid, you may schedule a pickup request at any time, easy for forwarders to do a prompt receive a prompt & valid quotation.',
    img: require('@/assets/EXW_Local_Manager/Instant Quote.png'),
    style: 'margin-bottom: 86px'
  },
  {
    id: 3,
    title: 'Order Status',
    content: 'Booking received -> Container released -> Truck dispatch -> Empty container pick up <br> -> Arrival at shipper -> Loading(inspection per request) -> Depart from shipper -> Arrive at Port Of Loading -> Customs release -> Port release -> Vessel berthing at Port Of Loading -> Depart from Port Of Loading -> Arrive at transit port -> Depart from transit port -> Arrive at Port Of Departure',
    img: require('@/assets/EXW_Local_Manager/Order Status.png'),
    style: 'margin-bottom: 55px'
  }
]
