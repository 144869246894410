<template>
  <div class="exw-localmanager-introduce">
    <div class="body">
    <div class="title">EXW Local Manager</div>
    <div class="title-underline"></div>
    <div class="content">
      <div class="content-text">
      Duckbill is 20 times more eﬃcient than the average industry level in ﬂeet
      management, dispatch, control, and is even enlarging the gap
      further....
      <br>
      For all your Trucking and Inspection needs across China and
      beyond…
      </div>
      <hr width="94%" color="#F6F6F6" />
      <div class="images" @mouseleave="mouseleaveFn">
        <EXWLocalManagerIntroduceItem  v-for="item in exwLocalManagerIntroduceItemsData" :key="item.id" :item="item" :exwLocalManagerIntroduceItemsData="exwLocalManagerIntroduceItemsData" @mouseenterChangeOtherImage="mouseenterChangeOtherImage"></EXWLocalManagerIntroduceItem>
      </div>
    </div>
    </div>
  </div>
</template>

<script>
import EXWLocalManagerIntroduceItem from './EXWLocalManagerIntroduceItem.vue'
import { exwLocalManagerIntroduceItemsData } from './data'
export default {
  data () {
    return {
      exwLocalManagerIntroduceItemsData
    }
  },
  components: {
    EXWLocalManagerIntroduceItem
  },
  methods: {
    mouseenterChangeOtherImage (id) {
      this.exwLocalManagerIntroduceItemsData.forEach(item => {
        if (id !== item.id) {
          item.img = item.deactiveImg
        } else {
          item.img = item.activeImg
        }
      })
    },
    mouseleaveFn () {
      this.exwLocalManagerIntroduceItemsData.forEach(item => {
        item.img = item.imgUrl
      })
    }
  }
}
</script>

<style lang="less" scoped>
@import url("../../../style/mixin.less");
.exw-localmanager-introduce {
  padding-top: 300px;
  .body {
    width: 1200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 70px;
    .title-underline {
      .title-underline(136px);
    }
    .content {
      position: relative;
      .content-text {
        padding: 0 25px;
        text-align: center;
      }
        .images {
          width: 100%;
          display: flex;
          margin-top: 32px;
        }
        hr {
          position: absolute;
          top: 167px;
          left: 15px;
          z-index: -999;
        }

    }

  }
}
</style>
